<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />

        <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">
            <template v-for="(item, key) in list_check_box"> 
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">                   
                    <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->

                    
                    <!-- Section Detail Start -->

                    <KeyDocument v-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>

                    <!--1indication start--> 
                    <Indication v-else-if=" key== 'detail_indication' && item.show"/>
                    <!--indication end-->
       
                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show"/>
                    <!-- treatment_line end -->

                    <!-- detail_Conclusion -->
                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />  

                    <!--key conclusion start-->           
                    <div class="content-onglets" v-else-if="key=='detail_key_conclusion' && item.show">
                        <p v-html="data.nihta['key_conclusion'] ? data.nihta['key_conclusion'] : '-'"></p>
                    </div>                             
                    <!--key conclusion end-->


                    <!--Clinical Aspects start-->        
                    <div class="content-onglets" v-else-if="key=='detail_ClinicalAspects' && item.show">                            
                        <div class="box-toggle textJustify" v-if="data.nihta['efficacy_and_safety']">
                            <a class="title title-tb" @click="efficacy_and_safety_Show = !efficacy_and_safety_Show" style="cursor:pointer;">                             
                                {{$t('Efficacy') }}
                                <em :class="efficacy_and_safety_Show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>                                                      
                            </a>                        
                            <div id="efficacy_and_safety" v-if="efficacy_and_safety_Show">
                                <p v-html="data.nihta['efficacy_and_safety']"></p>                 
                            </div>
                        </div>                        
                    </div>               
                    <!--Clinical Aspects end-->

                    <!--Clinical Aspects start-->
                    <div class="content-onglets" v-else-if="key=='detail_safety' && item.show">
                        <div class="box-toggle textJustify" v-if="data.nihta['safety']">
                            <a class="title title-tb" @click="safety_Show = !safety_Show" style="cursor:pointer;">                                
                               {{ $t('Safety') }}
                                <em :class="safety_Show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>                                                                                    
                            </a>                        
                            <div id="safety" v-if="safety_Show">
                                <p v-html="data.nihta['safety']"></p>
                            </div>
                        </div>                        
                    </div>        
                    <!--Clinical Aspects end-->


                    <!--Economic Evaluation start-->     
                    <div class="content-onglets" v-else-if="key=='detail_EconomicEvaluation_txt' && item.show">
                        <div class="box-toggle textJustify " v-if="data.nihta['cost_effectiveness']">
                            <a  class="title title-tb" @click="cost_effectiveness_Show = !cost_effectiveness_Show" style="cursor:pointer;">                              
                                Cost Effectiveness <em :class="cost_effectiveness_Show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                            </a>                        
                            <div id="cost_effectiveness"  v-if="cost_effectiveness_Show">
                                <p v-html="data.nihta['cost_effectiveness']"></p>                    
                            </div>
                        </div>                        
                        <div class="box-toggle textJustify"  v-if="data.nihta['budget_impact']">
                            <a  class="title title-tb" @click="budget_impact_Show = !budget_impact_Show" style="cursor:pointer;">                                   
                                Budget Impact <em :class="budget_impact_Show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>                             
                            </a>                        
                            <div id="budget_impact" v-if="budget_impact_Show">
                                <p v-html="data.nihta['budget_impact']"></p>                            
                            </div>
                        </div>                        
                    </div>

                    <!--Economic Evaluation end-->

                    <!--Cost start-->
                    <div class="content-onglets table" v-else-if="key=='detail_NihtaPrice' && item.show">                        
                        <table class="comparateur2 " style="text-align:center" v-if="data.nihta['nihta_price'].length > 0 || data.nihta['same_price_as_comparators']">
                            <thead>
                                <tr>
                                    <th width="40%" style="text-align: left;">{{$t('Description of therapy')}}</th>      
                                    <th width="30%" >{{$t('Price')}}</th> 
                                    <th width="30%" >{{$t('Price incl')}}</th> 
                                </tr>
                            </thead>
                            <tbody>                                    
                                <tr v-for="(items,keys) in data.nihta['nihta_price'] " :key="'nihta_price'+keys">                        
                                    <td>{{items['description_therapy'] ? items['description_therapy'] : '-'}} </td>    
                                    <td>{{items['price'] ? items['price'] : '-' }} </td> 
                                    <td>{{items['price_incl'] ? items['price_incl'] : '-' }} </td> 
                                </tr>           
                                <tr v-if="data.nihta['same_price_as_comparators']">
                                    <th width="40%">{{$t('Same price as comparators')}}</th>
                                    <td width="60%" colspan="2">{{data.nihta['same_price_as_comparators']}}</td>                        
                                </tr>   
                            </tbody>
                        </table>                          
                        
                        <table class="comparateur2"  style="text-align:center" v-if="data.nihta['nihta_cost_treatment'].length > 0">
                            <thead>
                                <tr>
                                    <th width="40%" style="text-align: left;">{{$t('Description of therapy')}}</th>    
                                    <th width="30%" >{{$t('Cost of treatment')}}</th>
                                    <th width="30%" >{{$t('Cost of treatment incl')}}</th>
                                </tr>
                            </thead>
                            <tbody>                                     
                                <tr v-for="(items,keys) in data.nihta['nihta_cost_treatment'] " :key="'nihta_cost_treatment'+keys">                        
                                    <td>{{ items['description_therapy'] ? items['description_therapy'] : '-' }} </td>
                                    <td>{{ items['cost_of_treatment'] ? items['cost_of_treatment'] : '-' }} </td>
                                    <td>{{ items['cost_of_treatment_incl'] ? items['cost_of_treatment_incl']  : '-' }} </td>
                                </tr>                                    
                            </tbody>
                        </table>                         
                    </div> 
                    <!--Cost end-->

                    <!--RealWorldData start-->   
                    <RealWorldData :data="data.nihta" v-else-if="key == 'detail_real_world_data' && item.show"/>
                    <!--RealWorldData end-->   
                      
                    <!--QualityOfLife start-->   
                    <QualityOfLife :data="data.nihta" v-else-if="key == 'detail_quality_of_life' && item.show"/>
                    <!--QualityOfLife end-->     

                    <!--IndirectComparison start-->   
                    <IndirectComparison :data="data.nihta" v-else-if="key == 'detail_indirect_comparison' && item.show"/>
                    <!--IndirectComparison end-->                     
 
                    <!--BudgetImpact start-->
                    <BudgetImpact :data="data.nihta['budget_impacts']" v-else-if="key == 'detail_BudgetImpact' && item.show" /> 
                    <!--BudgetImpact end-->    

                    <!-- 'sub_detail/evaluation_economic.ctp'  -->
                    <EvaluationEconomic v-else-if="key == 'detail_EvaluationEconomic' && item.show" :data="data.nihta.evaluation_economic" />

                    <!-- 'sub_detail/EconomicEvaluation.ctp' -->
                    <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" />
                        
                   <!-- EvaluationEssaisClinique start-->        
                    <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>
                    <!--EvaluationEssaisClinique end-->

                    <!-- Section Detail End -->

                </div>
            </template>

            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->
			
            
            <!-- Link Section End -->

        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue'
import TreatmentLine from '../treatment_line.vue'
import RealWorldData from '../real_world_data.vue'
import QualityOfLife from '../quality_of_life.vue'
import IndirectComparison from '../indirect_comparison.vue'
import EvaluationEconomic from '../evaluation_economic.vue'
import Conclusion from '../conclusion.vue'
import LinkAgency from '../LinkAgency.vue'
import BudgetImpact from '../budget_impact.vue'
import KeyDocument from '../key_document.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import Indication from '../Indication.vue'
import {check_detail_conclusion, key_documents} from '../../../utils'


export default {
    name: 'nihta',
    components : {
        LeftSection,
        TreatmentLine,
        RealWorldData,
        QualityOfLife,
        IndirectComparison,
        EvaluationEconomic,        
        Conclusion,
        LinkAgency,
        BudgetImpact,
        KeyDocument, 
        EssaisClinique,
        Indication
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_indication : {'title' : 'Indication', 'show' : true, 'enable' : false, 'orange_text':false},
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_Conclusion : {'title' : 'Rationale and commentary', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_key_conclusion : {'title' : 'Key conclusion', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_ClinicalAspects : {'title' : 'Efficacy', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_safety : {'title' : 'Safety', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_EconomicEvaluation_txt : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':false}, //**
                detail_NihtaPrice : {'title' : 'Cost', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_BudgetImpact : {'title' : 'Budget impact', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_real_world_data : {'title' : 'Real World Data', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_quality_of_life : {'title' : 'Quality of Life', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_indirect_comparison : {'title' : 'Indirect comparison', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_EvaluationEconomic : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_EconomicEvaluation : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_clinical_trials : {'title':'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true }, 
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false},
            },
            efficacy_and_safety_Show : true,
            safety_Show : true,
            cost_effectiveness_Show : false,
            budget_impact_Show : false,
        }
    },
    created(){
        // condition to show check box in left section [enable]
        if (this.data && this.data.nihta ) {

            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            }
            if (this.data['indication_en']){
                this.list_check_box['detail_indication'].enable = true
            }
            if(this.data.treatment_line || this.data.specificity || this.data.administration)  { 
                this.list_check_box['treatment_line'].enable = true
            }
            if(this.check_detail_conclusion(this.data, this.$i18n.locale) || this.data.nihta['rationale_commentary'] ) { 
                this.list_check_box['detail_Conclusion'].enable = true
            }
            if (this.data.nihta['key_conclusion']) {
                this.list_check_box['detail_key_conclusion'].enable = true
            }
            if(this.data.nihta['efficacy_and_safety']){
                this.list_check_box['detail_ClinicalAspects'].enable = true
            }
            if (this.data.nihta['safety']){
                this.list_check_box['detail_safety'].enable = true
            }
            if (this.data.nihta['cost_effectiveness'] || this.data.nihta['budget_impact']){
                this.list_check_box['detail_EconomicEvaluation_txt'].enable = true
            }
            if ( this.data.nihta['nihta_price'] && this.data.nihta['nihta_price'].length > 0 || this.data.nihta['nihta_cost_treatment'] && this.data.nihta['nihta_cost_treatment'].length > 0){
                this.list_check_box['detail_NihtaPrice'].enable = true
            }
                if(this.data.nihta['budget_impacts'] && this.data.nihta['budget_impacts'].length > 0 ) { 
                this.list_check_box['detail_BudgetImpact'].enable = true
            }
            if (this.data.nihta['real_world_data_presentated'] || this.data.nihta['type_of_rwd'] || this.data.nihta['population_rwd'] || this.data.nihta['accepted_rwd'] ){
                this.list_check_box['detail_real_world_data'].enable = true
            }
            if ( this.data.nihta['quality_of_life_data'] || this.data.nihta['type_of_qol_data_presented'] || this.data.nihta['population_qol'] || this.data.nihta['accepted_rwd']) {
                this.list_check_box['detail_quality_of_life'].enable = true
            }
            if ( this.data.nihta['indirect_comparison_data'] == 'Yes' || this.data.nihta['population_ic'] || this.data.nihta['acepted_ic']) {
                this.list_check_box['detail_indirect_comparison'].enable = true
            }
            if(this.data.nihta.evaluation_economic.length > 0) { 
                this.list_check_box['detail_EvaluationEconomic'].enable = true
            }                
            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 
                this.list_check_box['detail_EconomicEvaluation'].enable = true
                this.list_check_box['detail_EvaluationEconomic'].enable = false                    
            }                                        
            // this.list_check_box['detail_EssaisClinique'].enable = false  
            if(this.data.essais_clinique &&  this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_clinical_trials'].enable = true
            }
        }
    },
    methods : {
        check_detail_conclusion,
        key_documents
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }    
    },
}
</script>

<style scoped>

    .box-toggle a:hover i {
        color: #ff6300;
    }
    .fa-angle-up {
        color : #ff6300;
    }
   

</style>