<template>
    <div class="content-onglets table">
        <table class="comparateur2 border-top-gray">              
            <tbody>             
                <tr v-if="data.real_world_data_presentated">
                    <th width="50%" style="text-align: left">Quality of Life data</th>
                    <td>{{ $t(data.real_world_data_presentated) }}</td>
                </tr>            
                <template v-if="data.type_of_qol_data_presented">
                    <tr>
                        <th colspan="2" class="text-center">Type of QoL data presented</th>                        
                    </tr>
                    <tr>
                        <td colspan="2"><p v-html="data.type_of_qol_data_presented"></p></td>                      
                    </tr>
                </template>
                <template v-if="data.population_qol">
                    <tr>
                        <th colspan="2" class="text-center">Population</th>                        
                    </tr>
                    <tr>
                        <td colspan="2"><p v-html="data.population_qol"></p></td>                   
                    </tr>
                </template>            
                <tr v-if="data.accepted_qol">
                    <th width="50%" style="text-align: left">Accepted</th>
                    <td>{{$t(data.accepted_qol)}}</td>
                </tr>                
            </tbody>
        </table>
    </div>          
</template>

<script>
export default {
    name : "QualityOfLife",
    props : {
        data : {
            style : Object
        }
    }
}
</script>

<style>

</style>