<template>
    <div class="content-onglets table">
        <table class="comparateur2 border-top-gray text-left"> 
            <tbody>                
                <tr v-if="data.indirect_comparison_data">
                    <th width="50%" style="text-align: left">Indirect comparison data</th>                        
                    <td>{{$t(data.indirect_comparison_data) }}</td> 
                </tr>
                <template v-if="data.population_ic">
                    <tr>
                        <th colspan="2" class="text-center" style="text-align: center !important;">Population</th>                        
                    </tr>
                    <tr>
                        <td colspan="2"><p v-html="data.population_ic"></p></td>                       
                    </tr>
                </template>                
                <tr v-if="data.acepted_ic">
                    <th width="50%" style="text-align: left">Accepted</th> 
                    <td>{{$t(data.acepted_ic)}}</td>
                </tr>
                
            </tbody>
        </table>
    </div>        
</template>

<script>
export default {
    name : "IndirectComparison",
    props : {
        data : {
            style : Object
        }
    }
}
</script>

<style>

</style>