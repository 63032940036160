<template>
    <div class="content-onglets table">
        <table class="comparateur2 border-top-gray">   
            <tbody>            
                    <tr v-if="data.real_world_data_presentated">
                        <th width="50%" style="text-align: left">{{$t('Real World Data presented')}}</th>
                        <td>{{$t(data.real_world_data_presentated)}}</td>
                    </tr>
                    <template  v-if="data.type_of_rwd">
                        <tr>
                            <th colspan="2" class="text-center">{{$t('Type of RWD')}}</th>                        
                        </tr>
                        <tr>
                            <td colspan="2"><p v-html="data.type_of_rwd"></p></td>                        
                        </tr> 
                    </template>         
                    <template v-if="data.population_rwd">
                        <tr>
                            <th colspan="2" class="text-center">{{$t('Population')}}</th>                        
                        </tr>
                        <tr>
                            <td colspan="2"><p v-html="data.population_rwd"></p></td>                        
                        </tr>
                    </template>
                    <tr v-if="data.accepted_rwd">
                        <th width="50%" style="text-align: left">{{$t('Accepted')}}</th>
                        <td>{{ $t(data.accepted_rwd) }}</td>
                    </tr>
                
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name : "RealWorldData",
    props : {
        data : {
            style : Object
        }
    }
}
</script>

<style>

</style>